import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import {
  languageReducer,
  edgeRenderingReducer,
  adaptiveFramerateReducer,
  speechToTextReducer,
  tempSpeechToTextResultReducer,
  speechToTextHistoryReducer,
  autoDetectionReducer,
} from '@avita-co-jp/avacom-redux';
import exampleReducer from './features/example';
import customerReducer from './features/customer/customer.slice';
import customerAnonymousAuthReducer from './features/customerAnonymousAuth/customerAnonymousAuth.slice';
import projectSettingReducer from './features/projectSetting/projectSetting.slice';
import dialogReducer from './features/dialog/dialog.slice';
import servicingReducer from './features/servicing/servicing.slice';
import waitingMovieReducer from './features/waitingMovie';
import authFirebaseReducer from './features/authFirebase';
import movieOrderReducer from './features/movieOrder';
import forceReloadReducer from './features/forceReload';
import firestoreRoomReducer from './features/firestoreRoom';
import firestoreChatReducer from './features/firestoreChat';
import webRoomReducer from './features/webRoom';
import isEndCallReducer from './features/isEndCall';
import isClickPrimaryButtonFixedReducer from './features/isClickPrimaryButtonFixed';
import isConnectedErrorReducer from './features/isConnectedError';
import firestoreRoomSessionReducer from './features/firestoreRoomSession';
import widgetReducer from './features/widgetArea';
import onlineCustomerStatusReducer from './features/onlineCustomerStatus';
import faceDetectionSettingsReducer from './features/faceDetectionSettings';
import azureAuthReducer from './features/azureAuth';
import unHandledInstancesReducer from './features/unHandledInstances';
import talkModeReducer from './features/talkMode';
import selectButtonReducer from './features/selectButton';
import kioskModeReducer from './features/kioskMode';
import aiResponseUrlReducer from './features/aiResponseUrl';
import ageVerificationBarcodeReducer from './features/ageVerificationBarcode';
import scenarioProgressRateReducer from './features/scenarioProgressRate';
import isScenarioDialogFreezeReducer from './features/isScenarioDialogFreeze';
import selectLanguageReducer from './features/selectLanguage';
import isReadyKlleonReducer from './features/isReadyKlleon';

export const store = configureStore({
  reducer: {
    example: exampleReducer,
    authFirebase: authFirebaseReducer,
    customer: customerReducer,
    customerAnonymousAuth: customerAnonymousAuthReducer,
    projectSetting: projectSettingReducer,
    waitingMovie: waitingMovieReducer,
    dialog: dialogReducer,
    servicing: servicingReducer,
    movieOrder: movieOrderReducer,
    forceReload: forceReloadReducer,
    firestoreRoom: firestoreRoomReducer,
    firestoreRoomSession: firestoreRoomSessionReducer,
    firestoreChat: firestoreChatReducer,
    webRoom: webRoomReducer,
    isEndCall: isEndCallReducer,
    isClickPrimaryButtonFixed: isClickPrimaryButtonFixedReducer,
    isConnectedError: isConnectedErrorReducer,
    widgetArea: widgetReducer,
    onlineCustomerStatus: onlineCustomerStatusReducer,
    edgeRendering: edgeRenderingReducer,
    faceDetectionSettings: faceDetectionSettingsReducer,
    azureAuth: azureAuthReducer,
    adaptiveBitRate: adaptiveFramerateReducer,
    language: languageReducer,
    speechToText: speechToTextReducer,
    tempSpeechToTextResult: tempSpeechToTextResultReducer,
    speechToTextHistory: speechToTextHistoryReducer,
    unHandledInstances: unHandledInstancesReducer,
    talkMode: talkModeReducer,
    autoDetection: autoDetectionReducer,
    selectButton: selectButtonReducer,
    kioskMode: kioskModeReducer,
    aiResponseUrl: aiResponseUrlReducer,
    ageVerificationBarcode: ageVerificationBarcodeReducer,
    scenarioProgressRate: scenarioProgressRateReducer,
    isScenarioDialogFreeze: isScenarioDialogFreezeReducer,
    selectLanguage: selectLanguageReducer,
    isReadyKlleon: isReadyKlleonReducer,
  },
  devTools: process.env.NEXT_PUBLIC_REDUX_ENVIRONMENT !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// NOTE: https://react-redux.js.org/using-react-redux/usage-with-typescript#define-typed-hooks
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
