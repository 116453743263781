import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

// Klleonのロードが完了したかどうか
export const isReadyKlleonSlice = createSlice({
  name: 'isReadyKlleon',
  initialState,
  reducers: {
    updateIsReadyKlleon: (state, action: PayloadAction<boolean>) => action.payload,
  },
});

export const { updateIsReadyKlleon } = isReadyKlleonSlice.actions;

export default isReadyKlleonSlice.reducer;
